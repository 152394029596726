export interface JobType {
  id: string;
  jobType: string;
  abbreviation?: string;
  jobPriorityId: string;
}

export interface JobPriority {
  id: string;
  priorityName: string;
  priority?: number;
}

export interface ImplementationType {
  id: string;
  implementationType: string;
}

export interface DPCUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface JobCategory {
  id: string;
  jobCategory: string;
}

export interface JobStatusType {
  id: string;
  jobStatusType: string;
}

export interface DisasterRecovery {
  id: string;
  name: string;
}
export interface JobCohort {
  id: string;
  name: string;
}
export interface JobUser {
  id: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  jobRole: {
    id: string;
    jobRole: string;
  };
}

export interface MilestoneLookup {
  id: string;
  milestoneName: string;
  milestoneGrouping: {
    id: string;
    name: string;
  };
  milestoneSection: {
    id: string;
    name: string;
  };
}

export interface mof {
  id: string;
  name: string;
  description: string;
}

export interface JobMilestone {
  id: string;
  milestoneDate: Date;
}

export interface SchoolImpactFeeDistrict {
  id: string;
  name: Date;
}

export interface Tmk {
  id: string;
  partialTmk: string;
}

interface AsaResponse {
  id: string;
  name: string | null;
  phone: string | null;
  fax: string | null;
  cell: string | null;
}

interface LocationAsaResponse {
  id: string;
  asa: AsaResponse | null;
}

interface ComplexAreaResponse {
  id: string;
  cas: string | null;
  casPhone: string | null;
  cabm: string | null;
  cabmPhone: string | null;
  itContactName: string | null;
  itContactPhone: string | null;
  name: string | null;
}

interface BipsDistrictResponse {
  id: string;
  facilitiesContact: string | null;
  facilitiesContactPhone: string | null;
  url: string | null;
  name: string | null;
}

interface FacilityTypeResponse {
  id: string;
  name: string;
}

export interface Location {
  id: string;
  address?: string;
  archaeology?: string;
  evac?: string;
  floodZone?: string;
  historic?: string;
  landIssues?: string;
  name: string;
  shortName?: string;
  other?: string;
  principal?: {
    id: string;
    name: string;
    phone: string;
    fax: string;
  };
  propertyOwnedBy: string;
  schoolImpactFeeDistrict: SchoolImpactFeeDistrict;
  sma: string;
  specDistrict: string;
  zoning: string;
  tmks: Tmk[];
  locationAsas: LocationAsaResponse[];
  complexArea: ComplexAreaResponse | null;
  bipsDistrict: BipsDistrictResponse | null;
  facilityType: FacilityTypeResponse | null;
  district?: {
    bmdsName: string | null;
    bmdsContact: string | null;
    name: string | null;
  };
  island?: {
    id: string;
    name: string;
  };
  complex?: {
    id: string;
    name: string;
  };
  legislatureHouse?: {
    district?: number;
    rep?: string;
  };
  legislatureSenate?: {
    district?: string;
    senator?: string;
  };
}

export interface Project {
  id: string;
  projectNumber: string;
  projectEstimatedPlans: number;
  projectEstimatedLand: number;
  projectEstimatedDesign: number;
  projectEstimatedConstruction: number;
  projectEstimatedEquipment: number;
  projectMaximo: {
    id: string;
    maximoWorkOrderNumber: string;
  };
  mof: mof;
}

export interface JobContract {
  id: string;
  contractNumber: string;
  contractName: string;
  status: string;
  originAmount: number;
  contingencyBalance: number;
  adjustContractAmount: number;
  adjustEndDate: Date;
  vendor: string;
}

export interface JobAuthorizationForChange {
  id?: string;
  afcNo: string;
  recommendDate: Date;
  totalValue: number;
  fundsAvail: number;
  pcdToPc?: Date;
  approvedDate: Date;
  note: string;
}

export interface InsufficientFundNotice {
  id?: string;
  addFee: number;
  date: Date;
  nifType?: string;
  nifTypeId?: string;
  authDate: Date;
  note: string;
  updatedAt?: Date;
  updatedBy?: string;
}

export interface JobPos {
  id?: string;
  amount: number;
  authDate: Date;
  note: string;
  paidDate: Date;
  payee: string;
  receivedDate: Date;
  lastUpdatedDate?: Date;
  lastUpdatedBy?: string;
}

export class ContractStatus {
  id: string;
  contractStatus: string;
}

export class Vendor {
  id: string;
  vendorName: string;
}

export class ContractDesign {
  id: string;
  basicServiceFee: string | null;
  reimbursableExpenses: string | null;
}

export interface Contract {
  id: string;
  contractNo: string | null;
  contractStatus: ContractStatus | null;
  vendor: Vendor | null;
  contractDesign: ContractDesign | null;
  contractConstruction: { id: string } | null;
}

export interface ContractJobDwoDto {
  id: string;
  contract: Contract | null;
}

export class ContractPscPo {
  id: string;
  poNo: string | null;
  poAmount: number | null;
  invoiceAmount: number | null;
  contractPsc: {
    contract: Contract;
  };
}

export interface CmOrganization {
  id: string;
  vendorName: string;
  vendorContacts: {
    id: string;
    vendorContactEmail: string;
    vendorContactFirstName: string;
    vendorContactLastName: string;
    vendorContactPhone: string;
  }[];
}

export interface JocPif {
  id: string;
  jocDesignteam: boolean | null;
  jocAsbuilts: boolean | null;
  jocHazardousMaterial: boolean | null;
  jocPermitStatus: {
    id: string;
    status: string;
  } | null;
  jocContractNumber: string | null;
  jocLocationNumber: string | null;
  vendor: {
    id: string;
    vendorName: string;
  } | null;
  jocOther: string | null;
  jocDpcCompletedDate: Date | null;
  jocDoeApprove: string | null;
  jocPifDate: Date | null;
  jocDesignteamPreparedby: string | null;
  jocDesignteamContract: string | null;
  jocPermitsContact: string | null;
  jocPermitsPreparedby: string | null;
  jocHazmatContact: string | null;
  jocHazmatPreparedby: string | null;
  jocAsbuiltsContract: string | null;
  jocAsbuiltsPreparedby: string | null;
  jocHazmatComments: string | null;
  jocEstConsEndDate: Date | null;
  jocEstConsStartDate: Date | null;
  jocJointScopeDate: Date | null;
  jocProposalDueDate: Date | null;
}

export interface JobPermit {
  id: string;
  jobId: string;
  jobPermitStatusId: string;
  jobPermitTypeId: string;
}

export type ConsultantSelection = {
  id: string;
  description: string;
  sortOrder?: number;
};

export type ConsultantDiscipline = {
  id: string;
  csDisciplineTitle: string;
};

export interface Job {
  id: string;
  externalId: string;
  jobNumber: string;
  jobName: string;
  fmsJobName?: string;
  jointScopeDate: Date | null;
  proposalDueDate: Date | null;
  constructionStartDate: Date | null;
  constructionEndDate: Date | null;
  currentCmTask?: string;
  currentPcTask?: string;
  scope?: string;
  generalComment?: string;
  budgetTrackerEnabled: boolean;
  fiscalYear: number;
  jobType: JobType;
  jobPriority?: JobPriority;
  implementationType?: ImplementationType;
  jobCategory?: JobCategory;
  jobUsers?: JobUser[];
  jobStatusType?: JobStatusType;
  lastMilestoneDate?: Date | null;
  lastMilestoneLookup?: MilestoneLookup;
  jobMilestones?: JobMilestone[];
  location?: Location;
  projects?: Project[];
  contracts?: JobContract[];
  authorizationForChanges?: JobAuthorizationForChange[];
  insufficientFundNotices?: InsufficientFundNotice[];
  contractJobDwoDtos: ContractJobDwoDto[];
  // contractPscPos: ContractPscPo[];
  cmOrganization: CmOrganization;
  jocPifs?: JocPif[];
  jobPermits: JobPermit[];
  cmAssigned: {
    id: string;
    firstName: string;
    lastName: string;
  };
  jobCodingRequestNo?: number;
  maximoWorkOrderNumber?: string;
  estimatedConstruction?: number;
  totalEstimatedCost?: number;
  totalExpenditure?: number;
  totalEncumbrance?: number;
  fmsTotalCost?: number;
  settlementPackage: SettlementPackage;
  disasterRecovery?: DisasterRecovery;
  disasterRecoveryComment?: string;
  jobCohort?: JobCohort;
  jobCohortComment?: string;
  contactInfo?: string;
  jobToProcoreProject?: {
    procoreProjectId: string;
  } | null;
  lastBaselineRevision?: {
    id: string;
    createdAt: Date;
    createdByUser: {
      id: string;
      firstName: string;
      lastName: string;
    };
  };
  csRequired: ConsultantSelection;
  csDiscipline: ConsultantDiscipline;
  hasJobSelection: boolean;
}

interface SettlementPackage {
  id: string;
  jobId: string;
  taxClearance: boolean;
  certificateOfGoodStanding: boolean;
  certificateOfVendorCompliance: boolean;
  acceptanceLetter: boolean;
  finalMonthlyEstimate: boolean;
  certificatesOfCompliance: boolean;
  warranties: boolean;
  changeOrderList: boolean;
  completedSubmittalRegister: boolean;
  asBuiltSignature: boolean;
  omManuals: boolean;
  contractorPayrollRecords: boolean;
  doeDailyLogs: boolean;
  surety: boolean;
  externalId: number;
}

export interface AllJobs {
  id: string;
  jobName: string;
  jobNumber: string;
  implementationType: string;
  priority: string;
  school: string;
  workOrderNumber: [];
}

export class ContractDesignResponse {
  id: string;
  contractId: string;
  contractNo: string;
  contractStatus: string;
  vendorName: string;
  basicServices: number;
  adjustedContractAmount: number;
}

export class ContractDesignOpenEndResponse {
  id: string;
  contractId: string;
  contractNo: string;
  contractStatus: string;
  vendorName: string;
  basicServices: number;
  adjustedContractAmount: number;
}

export class ContractDtoResponse {
  id: string;
  contractId: string;
  contractNo: string;
  contractStatus: string;
  vendorName: string;
  basicServices: number;
  invoiceAmount: number;
}

export class ContractConstructionResponse {
  id: string;
  contractId: string;
  contractNo: string;
  contractStatus: string;
  vendorName: string;
  originalContractAmount: number;
  adjustedContractAmount: number;
  contingencyBalance: number;
  adjustedEndDate: Date;
}

export class ContractConstructionManagementResponse {
  id: string;
  contractId: string;
  contractNo: string;
  contractStatus: string;
  vendorName: string;
  basicServices: number;
  adjustedContractAmount: number;
}

export class ContractDwoResponse {
  id: string;
  contractId: string;
  contractNo: string;
  vendorName: string;
  contractStatus: string;
  basicServices: number;
  invoiceAmount: number;
}

export class ContractPscPoResponse {
  id: string;
  poNo: string | null;
  vendorName: string;
  poAmount: number;
  invoiceAmount: number;
}

export class ContractJocPoResponse {
  id: string;
  poNo: string | null;
  vendorName: string;
  poAmount: number;
}
